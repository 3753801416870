import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICardSettings } from 'src/app/interface/ICardSettings.interface';
import { Promotions } from 'src/app/model/promotion.model';
import { CardSettingsService } from 'src/app/services/card-settings/card-settings.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-special-offer-dialog',
  templateUrl: './special-offer-dialog.component.html',
  styleUrls: ['./special-offer-dialog.component.scss'],
})
export class SpecialOfferDialogComponent {
  promotionOffer: Promotions;
  cardSettings: ICardSettings;
  isChangingStatus: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogRef: { promotionOffer: Promotions; settings: ICardSettings },
    readonly helperService: HelperService,
    private cardSettingsServices: CardSettingsService,
    private router: Router
  ) {
    this.promotionOffer = this.dialogRef.promotionOffer;
    this.cardSettings = this.dialogRef.settings;
  }

  async saveUserSettings() {
    try {
      this.isChangingStatus = true;

      this.cardSettings.showSpecialOffer = !this.cardSettings.showSpecialOffer;

      await this.cardSettingsServices.updateCardSettings(this.cardSettings);
    } catch (errCritical) {
    } finally {
      this.isChangingStatus = false;
    }
  }

  goToPayment(): void {
    localStorage.setItem('promotion-data', JSON.stringify(this.promotionOffer));
    this.router.navigate([
      './payment-page',
      {
        card: localStorage.getItem('card-code'),
      },
    ]);
  }
}
