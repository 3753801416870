(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"logoH__atlas_1", frames: [[0,0,223,222]]}
];


(lib.AnMovieClip = function(){
	this.actionFrames = [];
	this.ignorePause = false;
	this.gotoAndPlay = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
	}
	this.play = function(){
		cjs.MovieClip.prototype.play.call(this);
	}
	this.gotoAndStop = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
	}
	this.stop = function(){
		cjs.MovieClip.prototype.stop.call(this);
	}
}).prototype = p = new cjs.MovieClip();
// symbols:



(lib.CachedBmp_1 = function() {
	this.initialize(ss["logoH__atlas_1"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.Interpolación7 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#F5DA49").s().p("AikDYQgPgPAAgVIAAjlIAAAAIAAgBQAAhKA1g1QA1g1BJAAQBLAAA1A1QA0A1AABKQAABKg0A1Qg1A0hLAAQgoAAglgSIAABFQAAAVgPAPQgPAPgVAAQgVAAgPgPgAg2hpQgXAXAAAgQAAAhAXAVQAXAXAfAAQAhAAAWgXQAXgVAAghQAAgggXgXQgWgXghAAQgfAAgXAXg");
	this.shape.setTransform(0.0056,-0.0007,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-19.6,-25.2,39.3,50.4);


(lib.Interpolación6 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#F5DA49").s().p("ABXCeQgoAWgvAAQhKAAg1g0Qg0g1AAhLQAAhJA0g1QA1g1BKAAQAuAAApAWQAQgWAaAAQAVAAAPAPQAPAPAAAVIAAEBQAAAVgPAPQgPAPgVAAQgaAAgQgWgAg2g2QgXAXAAAfQAAAhAXAWQAXAXAfAAQAgAAAXgXQAXgWAAghQAAgfgXgXQgXgXggAAQggAAgWAXg");
	this.shape.setTransform(0.0321,-0.0063,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-19.6,-19.6,39.3,39.3);


(lib.Interpolación5 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#F5DA49").s().p("AikDYQgPgPAAgVIAAjmQAAhKA1g1QA1g1BJAAQBLAAA1A1QA0A1AABKQAABKg0A1Qg1A0hLAAQgoAAglgSIAABFQAAAVgPAPQgPAPgVAAQgVAAgPgPgAg2hpQgXAXAAAgQAAAhAXAVQAXAXAfAAQAhAAAWgXQAXgVAAghQAAgggXgXQgWgXghAAQgfAAgXAXg");
	this.shape.setTransform(0.0192,-0.0007,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-19.6,-25.2,39.3,50.4);


(lib.Interpolación4 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#F5DA49").s().p("AgjCgQgPgPAAgVIAAhJIhJAAQgVAAgPgPQgPgPAAgVQAAgUAPgPQAPgPAVAAIBJAAIAAhJQAAgVAPgPQAPgPAUAAQAWAAAOAPQAPAPAAAVIAABJIBJAAQAWAAAOAPQAPAPAAAUQAAAVgPAPQgOAPgWAAIhJAAIAABJQAAAVgPAPQgOAPgWAAQgUAAgPgPg");
	this.shape.setTransform(0.0312,0.0254,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-19,-19,38.1,38.1);


(lib.Interpolación3 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#2A84C4").s().p("ABdDYQgPgPAAgVIAAhFQglASgpAAQhEAAgygtQgzgtgJhCIgBgMIAAiMQAAgVAPgPQAPgPAVAAQAVAAAPAPQAPAPAAAVIAACBQAAAhAXAVQAXAXAfAAQAgAAAXgXQAXgVAAghIAAiBQAAgVAPgPQAPgPAVAAQAVAAAPAPQAPAPAAAVIAAFnQAAAVgPAPQgPAPgVAAQgVAAgPgPg");
	this.shape.setTransform(0.0199,-0.0007,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-19.6,-25.2,39.3,50.4);


(lib.Interpolación2 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#2A84C4").s().p("ABdDYQgPgOAAgWIAAiBQAAghgXgVQgXgXggAAQgfAAgXAXQgXAWAAAgIAACBQAAAWgPAOQgPAPgVAAQgVAAgPgPQgPgOAAgWIAAlnQAAgWAPgOQAPgPAVAAQAVAAAPAPQAPAOAAAWIAABEQAkgRApAAQBEAAAzAtQAzAuAJBCQABAFAAAHIAACLQAAAWgPAOQgPAPgVAAQgVAAgPgPg");
	this.shape.setTransform(0.0363,-0.0071,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-19.6,-25.1,39.3,50.3);


(lib.Interpolación1 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.CachedBmp_1();
	this.instance.setTransform(-55.8,-55.5,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-55.8,-55.5,111.5,111);


(lib.ClipGroup_6 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AiuCvIAAldIFdAAIAAFdg");
	mask.setTransform(17.525,17.5);

	// Capa_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("AgjChQgPgQAAgUIAAhKIhJAAQgVAAgPgPQgPgPAAgVQAAgUAPgPQAPgPAVAAIBJAAIAAhJQAAgVAPgPQAPgPAUAAQAVAAAPAPQAPAPAAAVIAABJIBJAAQAVAAAPAPQAPAPAAAUQAAAVgPAPQgPAPgVAAIhJAAIAABKQAAAUgPAQQgPAOgVAAQgUAAgPgOg");
	this.shape.setTransform(17.525,17.5);

	var maskedShapeInstanceList = [this.shape];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_6, new cjs.Rectangle(0,0,35.1,35), null);


(lib.ClipGroup_5 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AizDnIAAnNIFnAAIAAHNg");
	mask.setTransform(18.025,23.125);

	// Capa_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("ABdDYQgPgPAAgVIAAiBQAAghgXgVQgXgXggAAQgfAAgXAXQgXAWAAAgIAACBQAAAVgPAPQgPAPgVAAQgVAAgPgPQgPgPAAgVIAAlnQAAgVAPgPQAPgPAVAAQAVAAAPAPQAPAPAAAVIAABEQAlgRAoAAQBEAAA0AtQAyAtAJBCIABAMIAACMQAAAVgPAPQgPAPgVAAQgVAAgPgPg");
	this.shape.setTransform(18.025,23.125);

	var maskedShapeInstanceList = [this.shape];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_5, new cjs.Rectangle(0,0,36.1,46.3), null);


(lib.ClipGroup_4 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AizDnIAAnNIFnAAIAAHNg");
	mask.setTransform(18.025,23.1);

	// Capa_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("ABeDYQgPgPgBgVIAAhEQgmARgoAAQhDAAg0guQgygsgIhDIgCgLIAAiNQAAgUAPgPQAOgPAWAAQAVAAAPAPQAPAPAAAUIAACCQAAAgAXAWQAXAXAfAAQAgAAAYgXQAWgWAAggIAAiCQABgUAPgPQAOgPAWAAQAUAAAQAPQAOAPAAAUIAAFoQAAAVgOAPQgQAPgUAAQgWAAgOgPg");
	this.shape.setTransform(18,23.1);

	var maskedShapeInstanceList = [this.shape];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_4, new cjs.Rectangle(0,0,36,46.2), null);


(lib.ClipGroup_3 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AizDnIAAnNIFnAAIAAHNg");
	mask.setTransform(18.025,23.1);

	// Capa_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("AikDYQgPgPAAgVIAAjmQAAhLA1g1QA1g0BJAAQBLAAA1A0QA0A1AABLQAABKg0A1Qg1A0hLAAQgnAAgmgRIAABEQAAAVgPAPQgPAPgVAAQgVAAgPgPgAg2hqQgXAYAAAgQAAAgAXAWQAXAXAfAAQAhAAAWgXQAXgWAAggQAAgggXgYQgWgWghAAQgfAAgXAWg");
	this.shape.setTransform(18.025,23.1);

	var maskedShapeInstanceList = [this.shape];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_3, new cjs.Rectangle(0,0,36.1,46.2), null);


(lib.ClipGroup_2 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AizC0IAAlnIFnAAIAAFng");
	mask.setTransform(18.025,18);

	// Capa_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("ABXCeQgoAWgvAAQhJAAg1g0Qg1g1AAhLQAAhKA1g1QA1g0BJAAQAuAAApAWQAPgWAbAAQAVAAAPAPQAPAPAAAUIAAECQAAAWgPAOQgPAPgVAAQgbAAgPgWgAg2g3QgXAYAAAfQAAAgAXAXQAXAXAfAAQAhAAAWgXQAXgXAAggQAAgfgXgYQgWgWghAAQgfAAgXAWg");
	this.shape.setTransform(18.025,18);

	var maskedShapeInstanceList = [this.shape];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_2, new cjs.Rectangle(0,0,36.1,36), null);


(lib.ClipGroup_1 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AizDnIAAnNIFnAAIAAHNg");
	mask.setTransform(18,23.1);

	// Capa_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("AikDYQgPgPAAgVIAAjmQAAhLA0g1QA1g0BKAAQBLAAA1A0QA0A1AABLQAABKg0A1Qg1A0hLAAQgnAAgmgRIAABEQAAAVgPAPQgPAPgVAAQgWAAgOgPgAg2hqQgXAYAAAgQAAAgAXAWQAXAXAfAAQAgAAAXgXQAXgWAAggQAAgggXgYQgXgWggAAQgfAAgXAWg");
	this.shape.setTransform(18,23.1);

	var maskedShapeInstanceList = [this.shape];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_1, new cjs.Rectangle(0,0,36,46.2), null);


(lib.Group_5 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.ClipGroup_1();
	this.instance.setTransform(18,23.1,1,1,0,0,0,18,23.1);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Group_5, new cjs.Rectangle(0,0,36,46.2), null);


(lib.Group_4 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.ClipGroup_2();
	this.instance.setTransform(18,18,1,1,0,0,0,18,18);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Group_4, new cjs.Rectangle(0,0,36.1,36), null);


(lib.Group_3 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.ClipGroup_3();
	this.instance.setTransform(18,23.1,1,1,0,0,0,18,23.1);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Group_3, new cjs.Rectangle(0,0,36.1,46.2), null);


(lib.Group_2 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.ClipGroup_4();
	this.instance.setTransform(18,23.1,1,1,0,0,0,18,23.1);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Group_2, new cjs.Rectangle(0,0,36.1,46.2), null);


(lib.Group_1 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.ClipGroup_5();
	this.instance.setTransform(18,23.1,1,1,0,0,0,18,23.1);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Group_1, new cjs.Rectangle(0,0,36.1,46.3), null);


(lib.Group = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.ClipGroup_6();
	this.instance.setTransform(17.5,17.5,1,1,0,0,0,17.5,17.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Group, new cjs.Rectangle(0,0,35.1,35), null);


(lib.Interpolación13 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.Group();
	this.instance.setTransform(1.65,1.65,1.0893,1.0893,0,0,0,17.6,17.6);
	this.instance.compositeOperation = "multiply";

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("Ag/C8QgbgaAAgmIAAghIghAAQgmAAgagaQgbgbAAgmQAAglAbgaQAagbAmAAIAhAAIAAghQAAgmAbgaQAbgbAkAAQAmAAAaAbQAbAaAAAmIAAAhIAhAAQAmAAAbAbQAaAaAAAlQAAAmgaAbQgbAagmAAIghAAIAAAhQAAAmgbAaQgaAbgmAAQgkAAgbgbg");
	this.shape.setTransform(0.0312,0.0254,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-23.4,-23.4,46.9,46.9);


(lib.Interpolación12 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.Group_2();
	this.instance.setTransform(1.65,1.65,1.0893,1.0893,0,0,0,18.1,23.2);
	this.instance.compositeOperation = "multiply";

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("ABBD1QgbgbAAgmIAAgOQgTADgTAAQhSAAg+g2Qg+g3gLhQQgCgJAAgKIAAiMQAAgmAbgaQAagbAmAAQAmAAAbAbQAaAaAAAmIAACBQAAAQALALQALALAPAAQAQAAALgLQALgLAAgQIAAiBQAAgmAbgaQAbgbAlAAQAmAAAbAbQAaAaAAAmIAAFnQAAAmgaAbQgbAagmAAQglAAgbgag");
	this.shape.setTransform(0.0199,-0.0007,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-23.9,-29.5,47.9,59.1);


(lib.Interpolación11 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.Group_3();
	this.instance.setTransform(1.55,1.65,1.0893,1.0893,0,0,0,18,23.2);
	this.instance.compositeOperation = "multiply";

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("AjAD1QgbgbAAgmIAAjmQAAhbBBhBQBAhABaAAQBbAABBBAQBABBAABbQAABahABBQhBBAhbAAQgSAAgTgDIAAAOQAAAmgbAbQgbAaglAAQgmAAgagagAgahNQgLALAAAQQAAAQALALQALALAPAAQAQAAALgLQALgLAAgQQAAgQgLgLQgLgLgQAAQgPAAgLALg");
	this.shape.setTransform(0.0192,-0.0007,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-23.9,-29.5,47.9,59.1);


(lib.Interpolación10 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.Group_5();
	this.instance.setTransform(1.6,1.65,1.0893,1.0893,0,0,0,18,23.2);
	this.instance.compositeOperation = "multiply";

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("AjAD1QgbgbAAgmIAAjmQAAhbBBhBQBAhABaAAQBbAABBBAQBABBAABbQAABahABBQhBBAhbAAQgSAAgTgDIAAAOQAAAmgbAbQgaAagmAAQgmAAgagagAgahNQgLAMAAAPQAAAQALALQALALAPAAQAQAAALgLQALgLAAgQQAAgQgLgLQgLgLgQAAQgOAAgMALg");
	this.shape.setTransform(0.0056,-0.0007,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-29.5,48,59.1);


(lib.Interpolación9 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.Group_4();
	this.instance.setTransform(1.65,1.65,1.0893,1.0893,0,0,0,18.1,18.1);
	this.instance.compositeOperation = "multiply";

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("ABPDNQglAPgqAAQhaAAhBhAQhAhBAAhbQAAhaBAhBQBBhABaAAQAoAAAnAPQAXgPAbAAQAmAAAbAbQAaAaAAAmIAAEBQAAAmgaAbQgbAagmAAQgcAAgWgPgAgagaQgLALAAAPQAAAQALALQALALAPAAQAQAAALgLQALgLAAgQQAAgPgLgLQgLgLgQAAQgPAAgLALg");
	this.shape.setTransform(0.0321,-0.0063,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-23.9,-24,47.9,48);


(lib.Interpolación8 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_1
	this.instance = new lib.Group_1();
	this.instance.setTransform(1.6,1.6,1.0893,1.0893,0,0,0,18.1,23.1);
	this.instance.compositeOperation = "multiply";

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#306495").s().p("ABBD1QgbgbAAgmIAAiBQAAgPgLgMQgLgLgQAAQgPAAgLALQgLAMAAAPIAACBQAAAmgbAbQgbAaglAAQgmAAgagaQgbgbAAgmIAAlnQAAgmAbgbQAagaAmAAQAlAAAbAaQAbAbAAAmIAAAOQATgDASAAQBSAAA/A3QA+A2ALBRIACATIAACLQAAAmgaAbQgbAagmAAQgmAAgagag");
	this.shape.setTransform(-0.0137,0.0429,1.0893,1.0893);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-29.5,48,59.1);


// stage content:
(lib.logoH = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Capa_2
	this.instance = new lib.Interpolación1("synched",0);
	this.instance.setTransform(461,59.5);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({_off:false},0).to({rotation:-360,x:57.4,y:57.6},19,cjs.Ease.cubicOut).to({startPosition:0},80).to({rotation:0,x:461.5,y:59.5},30,cjs.Ease.cubicIn).to({startPosition:0},6).wait(1));

	// Capa_3
	this.instance_1 = new lib.Interpolación2("synched",0);
	this.instance_1.setTransform(167.8,150.5);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(25).to({_off:false},0).to({x:165.7,y:57.5},9,cjs.Ease.quartOut).to({scaleX:1.1695,scaleY:1.1695},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},66).to({regY:1.2,scaleX:0.0417,scaleY:0.0417,x:165.75},6,cjs.Ease.cubicOut).wait(21));

	// Capa_4
	this.instance_2 = new lib.Interpolación3("synched",0);
	this.instance_2.setTransform(330.7,161.85);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(6).to({_off:false},0).to({x:328.6,y:68.85},9,cjs.Ease.quartOut).to({scaleX:1.1472,scaleY:1.1472},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},97).to({regX:1.4,scaleX:0.0374,scaleY:0.0374,x:328.65},6,cjs.Ease.cubicOut).wait(9));

	// Capa_5
	this.instance_3 = new lib.Interpolación4("synched",0);
	this.instance_3.setTransform(376.55,156.35);
	this.instance_3._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(2).to({_off:false},0).to({x:374.45,y:63.35},9,cjs.Ease.quartOut).to({scaleX:1.2132,scaleY:1.2132},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},103).to({scaleX:0.0405,scaleY:0.0405},6,cjs.Ease.cubicOut).wait(7));

	// Capa_6
	this.instance_4 = new lib.Interpolación5("synched",0);
	this.instance_4.setTransform(290.15,161.85);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(10).to({_off:false},0).to({x:288.05,y:68.85},9,cjs.Ease.quartOut).to({scaleX:1.1472,scaleY:1.1472},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},89).to({regY:1.4,scaleX:0.0354,scaleY:0.0354,y:68.9},6,cjs.Ease.cubicOut).wait(13));

	// Capa_7
	this.instance_5 = new lib.Interpolación6("synched",0);
	this.instance_5.setTransform(208.6,156.3);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(19).to({_off:false},0).to({x:206.5,y:63.3},9,cjs.Ease.quartOut).to({scaleX:1.1812,scaleY:1.1812},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},74).to({scaleX:0.0313,scaleY:0.0313},6,cjs.Ease.cubicOut).wait(19));

	// Capa_8
	this.instance_6 = new lib.Interpolación7("synched",0);
	this.instance_6.setTransform(249.15,161.85);
	this.instance_6._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(15).to({_off:false},0).to({x:247.05,y:68.85},9,cjs.Ease.quartOut).to({scaleX:1.1472,scaleY:1.1472},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},81).to({scaleX:0.0414,scaleY:0.0414,x:247,y:68.8},6,cjs.Ease.cubicOut).wait(16));

	// Capa_9
	this.instance_7 = new lib.Interpolación8("synched",0);
	this.instance_7.setTransform(167.85,150.45);
	this.instance_7._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(25).to({_off:false},0).to({x:165.75,y:57.45},9,cjs.Ease.quartOut).to({scaleX:1.1695,scaleY:1.1695},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},66).to({regY:1.2,scaleX:0.0417,scaleY:0.0417,y:57.5},6,cjs.Ease.cubicOut).wait(21));

	// Capa_10
	this.instance_8 = new lib.Interpolación9("synched",0);
	this.instance_8.setTransform(208.6,156.3);
	this.instance_8._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(19).to({_off:false},0).to({x:206.5,y:63.3},9,cjs.Ease.quartOut).to({scaleX:1.1812,scaleY:1.1812},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},74).to({scaleX:0.0313,scaleY:0.0313},6,cjs.Ease.cubicOut).wait(19));

	// Capa_11
	this.instance_9 = new lib.Interpolación10("synched",0);
	this.instance_9.setTransform(249.15,161.85);
	this.instance_9._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(15).to({_off:false},0).to({x:247.05,y:68.85},9,cjs.Ease.quartOut).to({scaleX:1.1472,scaleY:1.1472},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},81).to({scaleX:0.0414,scaleY:0.0414,x:247,y:68.8},6,cjs.Ease.cubicOut).wait(16));

	// Capa_12
	this.instance_10 = new lib.Interpolación11("synched",0);
	this.instance_10.setTransform(290.15,161.85);
	this.instance_10._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(10).to({_off:false},0).to({x:288.05,y:68.85},9,cjs.Ease.quartOut).to({scaleX:1.1472,scaleY:1.1472},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},89).to({regY:1.4,scaleX:0.0354,scaleY:0.0354,y:68.9},6,cjs.Ease.cubicOut).wait(13));

	// Capa_13
	this.instance_11 = new lib.Interpolación12("synched",0);
	this.instance_11.setTransform(330.7,161.85);
	this.instance_11._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(6).to({_off:false},0).to({x:328.6,y:68.85},9,cjs.Ease.quartOut).to({scaleX:1.1472,scaleY:1.1472},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},97).to({regX:1.4,scaleX:0.0374,scaleY:0.0374,x:328.65},6,cjs.Ease.cubicOut).wait(9));

	// Capa_14
	this.instance_12 = new lib.Interpolación13("synched",0);
	this.instance_12.setTransform(376.55,156.35);
	this.instance_12._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(2).to({_off:false},0).to({x:374.45,y:63.35},9,cjs.Ease.quartOut).to({scaleX:1.2132,scaleY:1.2132},5,cjs.Ease.quartOut).to({scaleX:1,scaleY:1},5).to({startPosition:0},103).to({scaleX:0.0405,scaleY:0.0405},6,cjs.Ease.cubicOut).wait(7));

	this._renderFirstFrame();

}).prototype = p = new lib.AnMovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,517.2,191.4);
// library properties:
lib.properties = {
	id: '00F0797A25BA4375BEC7C4ED9EEFA2E2',
	width: 405,
	height: 115,
	fps: 30,
	color: "#FFFFFF",
	opacity: 1.00,
	manifest: [
		{src:"assets/images/img/logoH__atlas_1.png", id:"logoH__atlas_1"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['00F0797A25BA4375BEC7C4ED9EEFA2E2'] = {
	getStage: function() { return exportRoot.stage; },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}


an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
	var lastW, lastH, lastS=1;		
	window.addEventListener('resize', resizeCanvas);		
	resizeCanvas();		
	function resizeCanvas() {			
		var w = lib.properties.width, h = lib.properties.height;			
		var iw = window.innerWidth, ih=window.innerHeight;			
		var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
		if(isResp) {                
			if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
				sRatio = lastS;                
			}				
			else if(!isScale) {					
				if(iw<w || ih<h)						
					sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==1) {					
				sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==2) {					
				sRatio = Math.max(xRatio, yRatio);				
			}			
		}
		domContainers[0].width = w * pRatio * sRatio;			
		domContainers[0].height = h * pRatio * sRatio;
		domContainers.forEach(function(container) {				
			container.style.width = w * sRatio + 'px';				
			container.style.height = h * sRatio + 'px';			
		});
		stage.scaleX = pRatio*sRatio;			
		stage.scaleY = pRatio*sRatio;
		lastW = iw; lastH = ih; lastS = sRatio;            
		stage.tickOnUpdate = false;            
		stage.update();            
		stage.tickOnUpdate = true;		
	}
}
an.handleSoundStreamOnTick = function(event) {
	if(!event.paused){
		var stageChild = stage.getChildAt(0);
		if(!stageChild.paused || stageChild.ignorePause){
			stageChild.syncStreamSounds();
		}
	}
}
an.handleFilterCache = function(event) {
	if(!event.paused){
		var target = event.target;
		if(target){
			if(target.filterCacheList){
				for(var index = 0; index < target.filterCacheList.length ; index++){
					var cacheInst = target.filterCacheList[index];
					if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
						cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
					}
				}
			}
		}
	}
}


})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;