import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { Promotions } from 'src/app/model/promotion.model';
import { User } from 'src/app/model/user.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-promotions-carousel',
  templateUrl: './promotions-carousel.component.html',
  styleUrls: ['./promotions-carousel.component.scss'],
})
export class PromotionsCarouselComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hideTitle: boolean = false;
  @Input() hideDots: boolean = false;
  @Input() dataSource: Promotions[] = [];
  @Input() enrollInformation: User = null;
  @Input() specialPromotionView: boolean = false;
  @Input() cardsPerPage: number = 2;

  showExclusivePromotions: boolean = false;

  currentPromos: Promotions[] = [];
  exclusivePromos: Promotions[] = [];
  allCustomerPromos: Promotions[] = [];
  currentIndex = 0;
  totalSlides = 0;
  totalAllCustomerSlides = 0;
  totalExclusiveSlides = 0;

  // Array de letras con su estado de color
  letters: { char: string; color: string }[] = [
    { char: 'R', color: 'green' },
    { char: 'E', color: 'green' },
    { char: 'C', color: 'green' },
    { char: 'Á', color: 'green' },
    { char: 'R', color: 'green' },
    { char: 'G', color: 'green' },
    { char: 'A', color: 'green' },
    { char: 'T', color: 'green' },
    { char: 'E', color: 'green' },
  ];
  private animationSubscription: Subscription | undefined;
  private currentStep: number = 0;
  private totalSteps: number = this.letters.length * 2; // Cambiar a rojo y luego a verde
  private intervalTime: number = 500; // Tiempo entre pasos en milisegundos

  constructor(private router: Router, readonly helperService: HelperService) {}

  ngOnInit() {
    this.filterPromotions(this.dataSource);
    this.startAnimation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] || changes['enrollInformation']) {
      if (changes['dataSource'].previousValue != changes['dataSource'].currentValue) {
        this.filterPromotions(changes['dataSource'].currentValue);
      }
    }
  }

  ngOnDestroy() {
    if (this.animationSubscription) {
      this.animationSubscription.unsubscribe();
    }
  }

  filterPromotions(dataSource: Promotions[]) {
    // Filtra promociones exclusivas y de todo público
    // En caso de solicitar este componente desde la vista especial de promociones, no se cargan las promociones para todo publico
    if (this.specialPromotionView) {
      this.exclusivePromos = dataSource.filter((promo) => !promo.isEnabledAllCustomers);
      this.totalExclusiveSlides = Math.ceil(this.exclusivePromos.length / this.cardsPerPage);
      this.totalSlides = this.totalAllCustomerSlides + this.totalExclusiveSlides;

      this.showExclusivePromotions = true;
      this.currentPromos = this.exclusivePromos;
    } else {
      this.allCustomerPromos = this.specialPromotionView ? [] : dataSource.filter((promo) => promo.isEnabledAllCustomers);
      this.exclusivePromos = dataSource.filter((promo) => !promo.isEnabledAllCustomers);

      this.totalAllCustomerSlides = this.specialPromotionView ? 0 : Math.ceil(this.allCustomerPromos.length / this.cardsPerPage);
      this.totalExclusiveSlides = Math.ceil(this.exclusivePromos.length / this.cardsPerPage);
      this.totalSlides = this.totalAllCustomerSlides + this.totalExclusiveSlides;

      if (this.enrollInformation === null) {
        this.showExclusivePromotions = false;
        this.currentPromos = this.allCustomerPromos;
      } else {
        this.showExclusivePromotions = false;
        this.currentPromos = this.exclusivePromos;
      }
    }
  }

  get displayedCards() {
    return this.currentPromos.slice(this.currentIndex * this.cardsPerPage, (this.currentIndex + 1) * this.cardsPerPage);
  }

  get dots() {
    return new Array(this.totalSlides);
  }

  nextSlide() {
    if (this.specialPromotionView) {
      // Evento unico para efectos en que el componente sea visualizado desde "Special Promotions"
      if (this.currentPromos === this.allCustomerPromos) {
        this.currentIndex++;
        if (this.currentIndex >= this.totalAllCustomerSlides) {
          this.currentPromos = this.exclusivePromos;
          this.showExclusivePromotions = true;
          this.currentIndex = 0;
        }
      } else {
        this.currentIndex = (this.currentIndex + 1) % this.totalExclusiveSlides;
      }
    } else {
      if (this.enrollInformation === null) {
        if (this.currentPromos === this.allCustomerPromos) {
          this.currentIndex++;
          if (this.currentIndex >= this.totalAllCustomerSlides) {
            this.currentPromos = this.exclusivePromos;
            this.showExclusivePromotions = true;
            this.currentIndex = 0;
          }
        } else if (this.currentPromos === this.exclusivePromos) {
          this.currentIndex++;
          if (this.currentIndex >= this.totalExclusiveSlides) {
            this.currentPromos = this.allCustomerPromos;
            this.showExclusivePromotions = false;
            this.currentIndex = 0;
          }
        }
      } else {
        this.currentIndex = (this.currentIndex + 1) % this.totalExclusiveSlides;
      }
    }
  }

  prevSlide() {
    if (this.specialPromotionView) {
      // Evento unico para efectos en que el componente sea visualizado desde "Special Promotions"
      if (this.currentPromos === this.allCustomerPromos) {
        this.currentIndex--;
        if (this.currentIndex < 0) {
          this.currentPromos = this.exclusivePromos;
          this.showExclusivePromotions = true;
          this.currentIndex = this.totalExclusiveSlides - 1;
        }
      } else {
        this.currentIndex = (this.currentIndex - 1 + this.totalExclusiveSlides) % this.totalExclusiveSlides;
      }
    } else {
      if (this.enrollInformation === null) {
        if (this.currentPromos === this.exclusivePromos) {
          this.currentIndex--;
          if (this.currentIndex < 0) {
            this.currentPromos = this.allCustomerPromos;
            this.showExclusivePromotions = false;
            this.currentIndex = this.totalAllCustomerSlides - 1;
          }
        } else if (this.currentPromos === this.allCustomerPromos) {
          this.currentIndex--;
          if (this.currentIndex < 0) {
            this.currentPromos = this.exclusivePromos;
            this.showExclusivePromotions = true;
            this.currentIndex = this.totalExclusiveSlides - 1;
          }
        }
      } else {
        this.currentIndex = (this.currentIndex - 1 + this.totalExclusiveSlides) % this.totalExclusiveSlides;
      }
    }
  }

  goToSlide(index: number) {
    this.currentIndex = index % this.totalSlides;

    if (this.enrollInformation === null) {
      if (index < this.totalAllCustomerSlides) {
        this.currentPromos = this.allCustomerPromos;
        this.showExclusivePromotions = false;
      } else {
        this.currentPromos = this.exclusivePromos;
        this.showExclusivePromotions = true;
        this.currentIndex = index - this.totalAllCustomerSlides;
      }
    } else {
      this.currentPromos = this.exclusivePromos;
      this.showExclusivePromotions = false;
    }
  }

  isCardEnabled(item: Promotions): boolean {
    return this.enrollInformation !== null || item.isEnabledAllCustomers;
  }

  goToPayment(source: Promotions): void {
    if (this.enrollInformation === null && source.isEnabledAllCustomers) {
      localStorage.setItem('promotion-data', JSON.stringify(source));
      this.router.navigate([
        './payment-page',
        {
          card: localStorage.getItem('card-code'),
        },
      ]);
    } else if (this.enrollInformation != null && !source.isEnabledAllCustomers) {
      localStorage.setItem('promotion-data', JSON.stringify(source));
      this.router.navigate([
        './payment-page',
        {
          card: localStorage.getItem('card-code'),
        },
      ]);
    }
  }

  isActiveDot(index: number): boolean {
    if (this.enrollInformation === null) {
      if (this.currentPromos === this.allCustomerPromos) {
        return index === this.currentIndex;
      } else if (this.currentPromos === this.exclusivePromos) {
        return index === this.currentIndex + this.totalAllCustomerSlides;
      }
    } else {
      return index === this.currentIndex;
    }
    return false;
  }

  startAnimation() {
    this.animationSubscription = interval(this.intervalTime).subscribe((step) => {
      // Calcula el paso actual dentro del ciclo de animación
      this.currentStep = step % this.totalSteps;

      if (this.currentStep < this.letters.length) {
        // Cambiar letra a rojo
        this.letters[this.currentStep].color = 'red';
      } else {
        // Cambiar letra a verde
        const index = this.currentStep - this.letters.length;
        this.letters[index].color = 'green';
      }
    });
  }
}
