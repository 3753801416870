<div *ngIf="!hideTitle">
  <div *ngIf="showExclusivePromotions; then TitleForExclusive; else TitleForEveryone"></div>

  <ng-template #TitleForEveryone>
    <p style="color: #1d405c; margin-bottom: 0px">
      <!-- <span style="font-weight: 600">RE</span> -->
      <span class="animated-text">
        <span *ngFor="let letter of letters" class="letter" [ngStyle]="{ color: letter.color }">
          {{ letter.char }}
        </span>
      </span>
      <small *ngIf="!enrollInformation">/CLIENTE NO PERSONALIZADO</small>
    </p>
  </ng-template>

  <ng-template #TitleForExclusive>
    <p style="text-align: left; color: #1d405c; margin-bottom: 0px">EXCLUSIVO CLIENTES PERSONALIZADOS</p>
  </ng-template>
</div>

<div class="carousel-container" (swipeleft)="nextSlide()" (swiperight)="prevSlide()">
  <div class="carousel">
    <div *ngFor="let item of displayedCards; let i = index" class="carousel-item">
      <section
        class="card-left"
        [style.padding-top]="item.typeOfPromotion === 'ONLY_CASH' ? '30px' : '30px'"
        [style.padding-bottom]="item.typeOfPromotion === 'ONLY_CASH' ? '30px' : '30px'"
        [style.background]="isCardEnabled(item) ? item.color ?? '#B06AB3' : 'grey'"
        (click)="goToPayment(item)"
      >
        <div class="row">
          <section class="col-5 text-center justified-center align-center" style="padding: 0px">
            <span class="text-yellow" style="font-size: smaller; line-height: 0.125rem">RECARGAS</span> <br />
            <span class="text-white" style="font-size: small; font-weight: bold">${{ item.amount | number }}</span>
          </section>
          <section class="col-1 text-center justified-center align-center" style="padding: 0px">
            <span style="color: white; font-size: x-large">+</span>
          </section>
          <section class="col-5 text-center justified-center align-center" style="padding: 0px">
            <span id="{{ item.typeOfPromotion }}" *ngIf="item.typeOfPromotion === 'ONLY_CASH'">
              <span class="text-white" style="font-size: smaller; line-height: 0.125rem">BONUS</span> <br />
              <span class="text-white" style="font-size: small; font-weight: bold">{{ item.bonusAmount | number }}</span>
            </span>

            <span id="{{ item.typeOfPromotion }}" *ngIf="item.typeOfPromotion === 'CASH_AND_TICKETS'">
              <span class="text-white" style="font-size: smaller; line-height: 0.125rem">ETICKETS</span> <br />
              <span class="text-white" style="font-size: small; font-weight: bold">{{ item.tickets | number }}</span>
            </span>

            <span id="{{ item.typeOfPromotion }}" *ngIf="item.typeOfPromotion === 'CASH_AND_GAMES'">
              <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUGADAS</span> <br />
              <span class="text-white" style="font-size: small; font-weight: bold">{{ item.token | number }}</span>
            </span>
          </section>
          <section class="col-1 text-center justified-center align-center" style="padding: 0px">
            <span style="color: white; font-size: x-large; position: relative; left: -2px; z-index: 10">=</span>
          </section>
        </div>
      </section>
      <section class="card-center"></section>
      <section
        class="card-right"
        [style.padding-top]="item.typeOfPromotion === 'ONLY_CASH' ? '30px' : '10px'"
        [style.padding-bottom]="item.typeOfPromotion === 'ONLY_CASH' ? '30px' : '10px'"
        [style.background]="isCardEnabled(item) ? helperService.lightenColor(item.color, 5) ?? helperService.lightenColor('#B06AB3', 5) : 'grey'"
        (click)="goToPayment(item)"
      >
        <div class="row">
          <section class="col-12 text-center justified-center align-center">
            <span id="{{ item.typeOfPromotion }}" *ngIf="item.typeOfPromotion === 'ONLY_CASH'">
              <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUEGAS</span> <br />
              <span class="text-white" style="font-size: x-small; font-weight: bold">{{ item.amount + item.bonusAmount | number }}</span>
            </span>

            <span id="{{ item.typeOfPromotion }}" *ngIf="item.typeOfPromotion === 'CASH_AND_TICKETS'">
              <div class="play-section">
                <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUEGAS</span> <br />
                <span class="text-white" style="font-size: x-small; font-weight: bold">{{ item.amount + item.bonusAmount | number }}</span> <br />
              </div>
              <div class="bonus-section">
                <span class="text-white" style="font-size: smaller; line-height: 0.125rem; font-weight: bold">+</span> <br />
                <span class="text-white" style="font-size: x-small; font-weight: bold">
                  <b>{{ item.tickets | number }}</b> ETICKETS
                </span>
              </div>
            </span>

            <span id="{{ item.typeOfPromotion }}" *ngIf="item.typeOfPromotion === 'CASH_AND_GAMES'">
              <div class="play-section">
                <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUEGAS</span> <br />
                <span class="text-white" style="font-size: x-small; font-weight: bold">{{ item.amount + item.bonusAmount | number }}</span> <br />
              </div>
              <div class="bonus-section">
                <span class="text-white" style="font-size: smaller; line-height: 0.125rem; font-weight: bold">+</span> <br />
                <span class="text-white" style="font-size: x-small; font-weight: bold">
                  <b>{{ item.token + item.bonusToken | number }}</b> JUGADAS
                </span>
              </div>
            </span>
          </section>
        </div>
      </section>
    </div>
  </div>
  <div class="dots" *ngIf="!this.hideDots">
    <span *ngFor="let dot of dots; let i = index" [class.active]="isActiveDot(i)" (click)="goToSlide(i)" class="dot"></span>
  </div>
</div>
