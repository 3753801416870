import { Amplify } from 'aws-amplify';
import { ApiRestService } from './services/api-rest/api-rest.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import { registerLocaleData } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TermAndConditionsDialogComponent } from './components/term-and-conditions-dialog/term-and-conditions-dialog.component';
import localeEsCL from '@angular/common/locales/es-CL';
import { PromotionsCarouselModule } from './components/promotions-carousel/promotions-carousel.module';
import { SpecialOfferDialogComponent } from './components/special-offer-dialog/special-offer-dialog.component';
registerLocaleData(localeEsCL, 'es-CL');

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: environment.amplify.GraphQL.endpoint,
      region: environment.amplify.GraphQL.region,
      defaultAuthMode: 'apiKey',
      apiKey: environment.amplify.GraphQL.apiKey,
    },
  },
});

@NgModule({
  declarations: [AppComponent, HeaderComponent, PrivacyPolicyDialogComponent, TermAndConditionsDialogComponent, SpecialOfferDialogComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FlexModule,
    HammerModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTableModule,
    MatToolbarModule,
    RouterModule,
    PromotionsCarouselModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [Title, ApiRestService, { provide: LOCALE_ID, useValue: 'es-CL' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router, private titleService: Title) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.titleService.setTitle('Happyland - Portal Promociones');
      }
    });
  }
}
