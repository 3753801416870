<mat-toolbar class="fixed-toolbar">
  <button mat-icon-button (click)="toggleMenu()" aria-label="Toggle menu icon">
    <mat-icon style="color: lightgray">{{ menuOpen ? 'close' : 'menu' }}</mat-icon>
  </button>
  <span class="flex-spacer"></span>
  <span class="navbar-logo" (click)="goToPortal()">
    <canvas #headerCanvas id="headerCanvas" class="navbar-logo"></canvas>
  </span>
  <span class="flex-spacer"></span>
  <button mat-icon-button class="example-icon" disabled="true" aria-label="Search icon">
    <!-- <mat-icon style="color: lightgray">search</mat-icon> -->
  </button>
</mat-toolbar>

<div class="menu-content" *ngIf="menuOpen">
  <div class="menu-item" [hidden]="!this.appSettings.appWithLoyaltyCard" (click)="changePage('/my-happy-plus')">Mi HAPPY+</div>
  <div class="menu-item" (click)="changeToHistoricalPage('/historical', 'tickets')">HISTORIAL E-TICKETS</div>
  <div class="menu-item" (click)="changeToHistoricalPage('/historical', 'gameplay')">HISTORIAL JUGADAS</div>
  <div class="menu-item" (click)="changePage('/portal')">RECARGA</div>
  <div class="menu-item-waiver" (click)="changePage('/waiver')">
    <div class="row">
      <div class="col-10 text-left">PASES CONSENTIMIENTO</div>
      <div class="col-2 text-right">
        <img style="display: flex" [src]="'assets/images/icons/ic_file.svg'" height="25vh" alt="Logo" />
      </div>
    </div>
  </div>
  <div class="mt-3 w-100 text-center"><small style="font-size: x-small">HAPPY+ by Happyland.com</small></div>
</div>

<div class="overlay" *ngIf="menuOpen" (click)="toggleMenu()"></div>
