<mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <section id="icon-and-message">
        <div class="d-flex" style="justify-content: center">
          <section id="icon">
            <img class="img-plus" [src]="'assets/images/icons/ic_ticket_discount.svg'" height="70vh" alt="Logo" />
          </section>
          <section id="comment">
            <p class="text-plus align-center" style="color: black">
              Hola, tenemos esta oferta<br />
              sólo para ti...
            </p>
          </section>
        </div>
      </section>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="carousel-container">
        <div class="carousel">
          <div class="carousel-item">
            <section
              class="card-left"
              style="padding-left: 10px"
              [style.padding-top]="this.promotionOffer.typeOfPromotion === 'ONLY_CASH' ? '30px' : '30px'"
              [style.padding-bottom]="this.promotionOffer.typeOfPromotion === 'ONLY_CASH' ? '30px' : '30px'"
              [style.background]="this.promotionOffer.color ?? '#B06AB3'"
            >
              <div class="row">
                <section class="col-5 text-center justified-center align-center" style="padding: 0px">
                  <span class="text-yellow" style="font-size: smaller; line-height: 0.125rem">RECARGAS</span> <br />
                  <span class="text-white" style="font-size: small; font-weight: bold">${{ this.promotionOffer.amount | number }}</span>
                </section>
                <section class="col-1 text-center justified-center align-center" style="padding: 0px">
                  <span style="color: white; font-size: x-large">+</span>
                </section>
                <section class="col-5 text-center justified-center align-center" style="padding: 0px">
                  <span id="{{ this.promotionOffer.typeOfPromotion }}" *ngIf="this.promotionOffer.typeOfPromotion === 'ONLY_CASH'">
                    <span class="text-white" style="font-size: smaller; line-height: 0.125rem">BONUS</span> <br />
                    <span class="text-white" style="font-size: small; font-weight: bold">{{ this.promotionOffer.bonusAmount | number }}</span>
                  </span>

                  <span id="{{ this.promotionOffer.typeOfPromotion }}" *ngIf="this.promotionOffer.typeOfPromotion === 'CASH_AND_TICKETS'">
                    <span class="text-white" style="font-size: smaller; line-height: 0.125rem">ETICKETS</span> <br />
                    <span class="text-white" style="font-size: small; font-weight: bold">{{ this.promotionOffer.tickets | number }}</span>
                  </span>

                  <span id="{{ this.promotionOffer.typeOfPromotion }}" *ngIf="this.promotionOffer.typeOfPromotion === 'CASH_AND_GAMES'">
                    <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUGADAS</span> <br />
                    <span class="text-white" style="font-size: small; font-weight: bold">{{ this.promotionOffer.token | number }}</span>
                  </span>
                </section>
                <section class="col-1 text-center justified-center align-center" style="padding: 0px">
                  <span style="color: white; font-size: x-large; position: relative; left: -2px; z-index: 10">=</span>
                </section>
              </div>
            </section>
            <section class="card-center"></section>
            <section
              class="card-right"
              [style.padding-top]="this.promotionOffer.typeOfPromotion === 'ONLY_CASH' ? '30px' : '10px'"
              [style.padding-bottom]="this.promotionOffer.typeOfPromotion === 'ONLY_CASH' ? '30px' : '10px'"
              [style.background]="helperService.lightenColor(this.promotionOffer.color, 5) ?? helperService.lightenColor('#B06AB3', 5)"
            >
              <div class="row">
                <section class="col-12 text-center justified-center align-center">
                  <span id="{{ this.promotionOffer.typeOfPromotion }}" *ngIf="this.promotionOffer.typeOfPromotion === 'ONLY_CASH'">
                    <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUEGAS</span> <br />
                    <span class="text-white" style="font-size: x-small; font-weight: bold">{{ this.promotionOffer.amount + this.promotionOffer.bonusAmount | number }}</span>
                  </span>

                  <span id="{{ this.promotionOffer.typeOfPromotion }}" *ngIf="this.promotionOffer.typeOfPromotion === 'CASH_AND_TICKETS'">
                    <div class="play-section">
                      <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUEGAS</span> <br />
                      <span class="text-white" style="font-size: x-small; font-weight: bold">{{ this.promotionOffer.amount + this.promotionOffer.bonusAmount | number }}</span>
                      <br />
                    </div>
                    <div class="bonus-section">
                      <span class="text-white" style="font-size: smaller; line-height: 0.125rem; font-weight: bold">+</span> <br />
                      <span class="text-white" style="font-size: x-small; font-weight: bold">
                        <b>{{ this.promotionOffer.tickets | number }}</b> ETICKETS
                      </span>
                    </div>
                  </span>

                  <span id="{{ this.promotionOffer.typeOfPromotion }}" *ngIf="this.promotionOffer.typeOfPromotion === 'CASH_AND_GAMES'">
                    <div class="play-section">
                      <span class="text-white" style="font-size: smaller; line-height: 0.125rem">JUEGAS</span> <br />
                      <span class="text-white" style="font-size: x-small; font-weight: bold">{{ this.promotionOffer.amount + this.promotionOffer.bonusAmount | number }}</span>
                      <br />
                    </div>
                    <div class="bonus-section">
                      <span class="text-white" style="font-size: smaller; line-height: 0.125rem; font-weight: bold">+</span> <br />
                      <span class="text-white" style="font-size: x-small; font-weight: bold">
                        <b>{{ this.promotionOffer.token + this.promotionOffer.bonusToken | number }}</b> JUGADAS
                      </span>
                    </div>
                  </span>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <!-- alinear boton al centro -->
    <div class="col-12" style="text-align-last: center">
      <button mat-raised-button mat-dialog-close color="primary" (click)="goToPayment()">
        <span class="mx-3" style="font-weight: bold">LA QUIERO!!!!</span>
      </button>
    </div>
  </div>
  <div class="row justify-content-end mt-4">
    <div class="col-2">
      <mat-slide-toggle
        class="green-toggle"
        style="line-height: 1 !important"
        [disabled]="this.isChangingStatus"
        [checked]="this.cardSettings.showSpecialOffer"
        (toggleChange)="saveUserSettings()"
      >
      </mat-slide-toggle>
    </div>
    <div class="col-10">
      <span class="text-plus" (click)="saveUserSettings()" style="cursor: pointer">
        Si no quieres visualizar esta oferta<br />
        Selecciona aquí
      </span>
      <p class="m-0 text-plus"><small>¡RECUERDA! Puedes activar esta oferta</small><br /><small>en tu sección MI HAPPY+ (Oferta Segmentada)</small></p>
    </div>
  </div>
</mat-dialog-content>
