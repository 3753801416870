import { Injectable } from '@angular/core';
import { APIService, CreateCardSettingsMutation, GetCardSettingsQuery } from '../api.service';
import { ICardSettings } from 'src/app/interface/ICardSettings.interface';

@Injectable({
  providedIn: 'root',
})
export class CardSettingsService {
  constructor(private apiService: APIService) {}

  /**
   * Obtener la configuración personalizada del usuario
   * @param settings
   * @returns
   */
  async getLatestCardSettings(account: number): Promise<ICardSettings> {
    try {
      const cardSettings: GetCardSettingsQuery = await this.apiService.GetCardSettings(account);

      if (cardSettings == null) {
        return null;
      }

      // Limpiar la respuesta para que solo contenga los campos de la interfaz
      delete cardSettings.__typename;

      return Promise.resolve(cardSettings);
    } catch (errCritical) {
      // console.error(errCritical);
      return Promise.reject(errCritical);
    }
  }

  /**
   * Crea una nueva configuración con el fin de personalizar la experiencia del usuario dentro de la web
   * @param settings
   * @returns
   */
  async createNewSettings(settings: ICardSettings) {
    try {
      const response: CreateCardSettingsMutation = await this.apiService.CreateCardSettings(settings);

      delete response.__typename;

      return Promise.resolve(response);
    } catch (errCritical) {
      // console.error(errCritical);
      return Promise.reject(errCritical);
    }
  }

  /**
   * Permite actualizar la experiencia del usuario dentro de la aplicación
   * @param settings
   * @returns
   */
  async updateCardSettings(settings: ICardSettings) {
    try {
      const response = await this.apiService.UpdateCardSettings(settings);
      return Promise.resolve(response);
    } catch (errCritical) {
      // console.error(errCritical);
      return Promise.reject(errCritical);
    }
  }
}
