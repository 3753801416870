/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { Client, generateClient, GraphQLResult } from "aws-amplify/api";
import { Observable } from "rxjs";

export type __SubscriptionContainer = {
  onCreateCardSettings: OnCreateCardSettingsSubscription;
  onCreateOneClickUserLog: OnCreateOneClickUserLogSubscription;
  onCreatePromotions: OnCreatePromotionsSubscription;
  onCreateSegments: OnCreateSegmentsSubscription;
  onCreateTrackingUsers: OnCreateTrackingUsersSubscription;
  onDeleteCardSettings: OnDeleteCardSettingsSubscription;
  onDeleteOneClickUserLog: OnDeleteOneClickUserLogSubscription;
  onDeletePromotions: OnDeletePromotionsSubscription;
  onDeleteSegments: OnDeleteSegmentsSubscription;
  onDeleteTrackingUsers: OnDeleteTrackingUsersSubscription;
  onUpdateCardSettings: OnUpdateCardSettingsSubscription;
  onUpdateOneClickUserLog: OnUpdateOneClickUserLogSubscription;
  onUpdatePromotions: OnUpdatePromotionsSubscription;
  onUpdateSegments: OnUpdateSegmentsSubscription;
  onUpdateTrackingUsers: OnUpdateTrackingUsersSubscription;
};

export type CreateCardSettingsInput = {
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type CardSettings = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type CreateOneClickUserLogInput = {
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OneClickUserLog = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type CreatePromotionsInput = {
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type Promotions = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type CreateSegmentsInput = {
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type Segments = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type CreateTrackingUsersInput = {
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type TrackingUsers = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type DeleteCardSettingsInput = {
  account: number;
};

export type DeleteOneClickUserLogInput = {
  uid: string;
};

export type DeletePromotionsInput = {
  uid: string;
};

export type DeleteSegmentsInput = {
  uid: string;
};

export type DeleteTrackingUsersInput = {
  uid: string;
};

export type UpdateCardSettingsInput = {
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type UpdateOneClickUserLogInput = {
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type UpdatePromotionsInput = {
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type UpdateSegmentsInput = {
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type UpdateTrackingUsersInput = {
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type TableCardSettingsFilterInput = {
  account?: TableIntFilterInput | null;
  segmentRef?: TableStringFilterInput | null;
  showSpecialOffer?: TableBooleanFilterInput | null;
};

export type TableIntFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type TableStringFilterInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
};

export type TableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type CardSettingsConnection = {
  __typename: "CardSettingsConnection";
  items?: Array<CardSettings | null> | null;
  nextToken?: string | null;
};

export type TableOneClickUserLogFilterInput = {
  authorizationCode?: TableIntFilterInput | null;
  cardNumber?: TableStringFilterInput | null;
  cardType?: TableStringFilterInput | null;
  email?: TableStringFilterInput | null;
  responseCode?: TableIntFilterInput | null;
  uid?: TableStringFilterInput | null;
  username?: TableStringFilterInput | null;
};

export type OneClickUserLogConnection = {
  __typename: "OneClickUserLogConnection";
  items?: Array<OneClickUserLog | null> | null;
  nextToken?: string | null;
};

export type TablePromotionsFilterInput = {
  amount?: TableIntFilterInput | null;
  basesDescription?: TableStringFilterInput | null;
  bonusAmount?: TableIntFilterInput | null;
  bonusToken?: TableIntFilterInput | null;
  color?: TableStringFilterInput | null;
  daysAvailable?: TableStringFilterInput | null;
  durationDescription?: TableStringFilterInput | null;
  enabled?: TableBooleanFilterInput | null;
  finalDate?: TableStringFilterInput | null;
  initialDate?: TableStringFilterInput | null;
  isDeleted?: TableBooleanFilterInput | null;
  isEnabledAllCustomers?: TableBooleanFilterInput | null;
  name?: TableStringFilterInput | null;
  paymentAmount?: TableIntFilterInput | null;
  segment_uid?: TableStringFilterInput | null;
  tickets?: TableIntFilterInput | null;
  token?: TableIntFilterInput | null;
  typeOfPromotion?: TableStringFilterInput | null;
  uid?: TableStringFilterInput | null;
  updateTime?: TableStringFilterInput | null;
};

export type PromotionsConnection = {
  __typename: "PromotionsConnection";
  items?: Array<Promotions | null> | null;
  nextToken?: string | null;
};

export type TableSegmentsFilterInput = {
  creationDate?: TableStringFilterInput | null;
  isDeleted?: TableBooleanFilterInput | null;
  lastUpdate?: TableStringFilterInput | null;
  name?: TableStringFilterInput | null;
  onlyNewCards?: TableBooleanFilterInput | null;
  promotionRef?: TableStringFilterInput | null;
  status?: TableBooleanFilterInput | null;
  uid?: TableStringFilterInput | null;
};

export type SegmentsConnection = {
  __typename: "SegmentsConnection";
  items?: Array<Segments | null> | null;
  nextToken?: string | null;
};

export type TableTrackingUsersFilterInput = {
  cardNumber?: TableFloatFilterInput | null;
  fidelity?: TableStringFilterInput | null;
  finalDate?: TableFloatFilterInput | null;
  initialDate?: TableFloatFilterInput | null;
  lastStepReached?: TableStringFilterInput | null;
  paymentAmount?: TableFloatFilterInput | null;
  paymentMethod?: TableStringFilterInput | null;
  promotionCash?: TableFloatFilterInput | null;
  promotionCashBonus?: TableFloatFilterInput | null;
  promotionTickets?: TableFloatFilterInput | null;
  promotionTokenBonus?: TableFloatFilterInput | null;
  promotionTokens?: TableFloatFilterInput | null;
  registeredEmail?: TableStringFilterInput | null;
  uid?: TableStringFilterInput | null;
};

export type TableFloatFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type TrackingUsersConnection = {
  __typename: "TrackingUsersConnection";
  items?: Array<TrackingUsers | null> | null;
  nextToken?: string | null;
};

export type CreateCardSettingsMutation = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type CreateOneClickUserLogMutation = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type CreatePromotionsMutation = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type CreateSegmentsMutation = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type CreateTrackingUsersMutation = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type DeleteCardSettingsMutation = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type DeleteOneClickUserLogMutation = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type DeletePromotionsMutation = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type DeleteSegmentsMutation = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type DeleteTrackingUsersMutation = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type UpdateCardSettingsMutation = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type UpdateOneClickUserLogMutation = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type UpdatePromotionsMutation = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type UpdateSegmentsMutation = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type UpdateTrackingUsersMutation = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type GetCardSettingsQuery = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type GetOneClickUserLogQuery = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type GetPromotionsQuery = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type GetSegmentsQuery = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type GetTrackingUsersQuery = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type ListCardSettingsQuery = {
  __typename: "CardSettingsConnection";
  items?: Array<{
    __typename: "CardSettings";
    account: number;
    segmentRef?: string | null;
    showSpecialOffer?: boolean | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListOneClickUserLogsQuery = {
  __typename: "OneClickUserLogConnection";
  items?: Array<{
    __typename: "OneClickUserLog";
    authorizationCode?: number | null;
    cardNumber?: string | null;
    cardType?: string | null;
    email?: string | null;
    responseCode?: number | null;
    uid: string;
    username?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListPromotionsQuery = {
  __typename: "PromotionsConnection";
  items?: Array<{
    __typename: "Promotions";
    amount?: number | null;
    basesDescription?: string | null;
    bonusAmount?: number | null;
    bonusToken?: number | null;
    color?: string | null;
    daysAvailable?: Array<string | null> | null;
    durationDescription?: string | null;
    enabled?: boolean | null;
    finalDate?: string | null;
    initialDate?: string | null;
    isDeleted?: boolean | null;
    isEnabledAllCustomers?: boolean | null;
    name?: string | null;
    paymentAmount?: number | null;
    segment_uid?: string | null;
    tickets?: number | null;
    token?: number | null;
    typeOfPromotion?: string | null;
    uid: string;
    updateTime?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListSegmentsQuery = {
  __typename: "SegmentsConnection";
  items?: Array<{
    __typename: "Segments";
    creationDate?: string | null;
    isDeleted?: boolean | null;
    lastUpdate?: string | null;
    name?: string | null;
    onlyNewCards?: boolean | null;
    promotionRef?: string | null;
    status?: boolean | null;
    uid: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListTrackingUsersQuery = {
  __typename: "TrackingUsersConnection";
  items?: Array<{
    __typename: "TrackingUsers";
    cardNumber?: number | null;
    fidelity?: string | null;
    finalDate?: number | null;
    initialDate?: number | null;
    lastStepReached?: string | null;
    paymentAmount?: number | null;
    paymentMethod?: string | null;
    promotionCash?: number | null;
    promotionCashBonus?: number | null;
    promotionTickets?: number | null;
    promotionTokenBonus?: number | null;
    promotionTokens?: number | null;
    registeredEmail?: string | null;
    uid: string;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateCardSettingsSubscription = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type OnCreateOneClickUserLogSubscription = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OnCreatePromotionsSubscription = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type OnCreateSegmentsSubscription = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type OnCreateTrackingUsersSubscription = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type OnDeleteCardSettingsSubscription = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type OnDeleteOneClickUserLogSubscription = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OnDeletePromotionsSubscription = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type OnDeleteSegmentsSubscription = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type OnDeleteTrackingUsersSubscription = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

export type OnUpdateCardSettingsSubscription = {
  __typename: "CardSettings";
  account: number;
  segmentRef?: string | null;
  showSpecialOffer?: boolean | null;
};

export type OnUpdateOneClickUserLogSubscription = {
  __typename: "OneClickUserLog";
  authorizationCode?: number | null;
  cardNumber?: string | null;
  cardType?: string | null;
  email?: string | null;
  responseCode?: number | null;
  uid: string;
  username?: string | null;
};

export type OnUpdatePromotionsSubscription = {
  __typename: "Promotions";
  amount?: number | null;
  basesDescription?: string | null;
  bonusAmount?: number | null;
  bonusToken?: number | null;
  color?: string | null;
  daysAvailable?: Array<string | null> | null;
  durationDescription?: string | null;
  enabled?: boolean | null;
  finalDate?: string | null;
  initialDate?: string | null;
  isDeleted?: boolean | null;
  isEnabledAllCustomers?: boolean | null;
  name?: string | null;
  paymentAmount?: number | null;
  segment_uid?: string | null;
  tickets?: number | null;
  token?: number | null;
  typeOfPromotion?: string | null;
  uid: string;
  updateTime?: string | null;
};

export type OnUpdateSegmentsSubscription = {
  __typename: "Segments";
  creationDate?: string | null;
  isDeleted?: boolean | null;
  lastUpdate?: string | null;
  name?: string | null;
  onlyNewCards?: boolean | null;
  promotionRef?: string | null;
  status?: boolean | null;
  uid: string;
};

export type OnUpdateTrackingUsersSubscription = {
  __typename: "TrackingUsers";
  cardNumber?: number | null;
  fidelity?: string | null;
  finalDate?: number | null;
  initialDate?: number | null;
  lastStepReached?: string | null;
  paymentAmount?: number | null;
  paymentMethod?: string | null;
  promotionCash?: number | null;
  promotionCashBonus?: number | null;
  promotionTickets?: number | null;
  promotionTokenBonus?: number | null;
  promotionTokens?: number | null;
  registeredEmail?: string | null;
  uid: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  public client: Client;
  constructor() {
    this.client = generateClient();
  }
  async CreateCardSettings(
    input: CreateCardSettingsInput
  ): Promise<CreateCardSettingsMutation> {
    const statement = `mutation CreateCardSettings($input: CreateCardSettingsInput!) {
        createCardSettings(input: $input) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateCardSettingsMutation>response.data.createCardSettings;
  }
  async CreateOneClickUserLog(
    input: CreateOneClickUserLogInput
  ): Promise<CreateOneClickUserLogMutation> {
    const statement = `mutation CreateOneClickUserLog($input: CreateOneClickUserLogInput!) {
        createOneClickUserLog(input: $input) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateOneClickUserLogMutation>response.data.createOneClickUserLog;
  }
  async CreatePromotions(
    input: CreatePromotionsInput
  ): Promise<CreatePromotionsMutation> {
    const statement = `mutation CreatePromotions($input: CreatePromotionsInput!) {
        createPromotions(input: $input) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreatePromotionsMutation>response.data.createPromotions;
  }
  async CreateSegments(
    input: CreateSegmentsInput
  ): Promise<CreateSegmentsMutation> {
    const statement = `mutation CreateSegments($input: CreateSegmentsInput!) {
        createSegments(input: $input) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateSegmentsMutation>response.data.createSegments;
  }
  async CreateTrackingUsers(
    input: CreateTrackingUsersInput
  ): Promise<CreateTrackingUsersMutation> {
    const statement = `mutation CreateTrackingUsers($input: CreateTrackingUsersInput!) {
        createTrackingUsers(input: $input) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <CreateTrackingUsersMutation>response.data.createTrackingUsers;
  }
  async DeleteCardSettings(
    input: DeleteCardSettingsInput
  ): Promise<DeleteCardSettingsMutation> {
    const statement = `mutation DeleteCardSettings($input: DeleteCardSettingsInput!) {
        deleteCardSettings(input: $input) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteCardSettingsMutation>response.data.deleteCardSettings;
  }
  async DeleteOneClickUserLog(
    input: DeleteOneClickUserLogInput
  ): Promise<DeleteOneClickUserLogMutation> {
    const statement = `mutation DeleteOneClickUserLog($input: DeleteOneClickUserLogInput!) {
        deleteOneClickUserLog(input: $input) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteOneClickUserLogMutation>response.data.deleteOneClickUserLog;
  }
  async DeletePromotions(
    input: DeletePromotionsInput
  ): Promise<DeletePromotionsMutation> {
    const statement = `mutation DeletePromotions($input: DeletePromotionsInput!) {
        deletePromotions(input: $input) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeletePromotionsMutation>response.data.deletePromotions;
  }
  async DeleteSegments(
    input: DeleteSegmentsInput
  ): Promise<DeleteSegmentsMutation> {
    const statement = `mutation DeleteSegments($input: DeleteSegmentsInput!) {
        deleteSegments(input: $input) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteSegmentsMutation>response.data.deleteSegments;
  }
  async DeleteTrackingUsers(
    input: DeleteTrackingUsersInput
  ): Promise<DeleteTrackingUsersMutation> {
    const statement = `mutation DeleteTrackingUsers($input: DeleteTrackingUsersInput!) {
        deleteTrackingUsers(input: $input) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <DeleteTrackingUsersMutation>response.data.deleteTrackingUsers;
  }
  async UpdateCardSettings(
    input: UpdateCardSettingsInput
  ): Promise<UpdateCardSettingsMutation> {
    const statement = `mutation UpdateCardSettings($input: UpdateCardSettingsInput!) {
        updateCardSettings(input: $input) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateCardSettingsMutation>response.data.updateCardSettings;
  }
  async UpdateOneClickUserLog(
    input: UpdateOneClickUserLogInput
  ): Promise<UpdateOneClickUserLogMutation> {
    const statement = `mutation UpdateOneClickUserLog($input: UpdateOneClickUserLogInput!) {
        updateOneClickUserLog(input: $input) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateOneClickUserLogMutation>response.data.updateOneClickUserLog;
  }
  async UpdatePromotions(
    input: UpdatePromotionsInput
  ): Promise<UpdatePromotionsMutation> {
    const statement = `mutation UpdatePromotions($input: UpdatePromotionsInput!) {
        updatePromotions(input: $input) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdatePromotionsMutation>response.data.updatePromotions;
  }
  async UpdateSegments(
    input: UpdateSegmentsInput
  ): Promise<UpdateSegmentsMutation> {
    const statement = `mutation UpdateSegments($input: UpdateSegmentsInput!) {
        updateSegments(input: $input) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateSegmentsMutation>response.data.updateSegments;
  }
  async UpdateTrackingUsers(
    input: UpdateTrackingUsersInput
  ): Promise<UpdateTrackingUsersMutation> {
    const statement = `mutation UpdateTrackingUsers($input: UpdateTrackingUsersInput!) {
        updateTrackingUsers(input: $input) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateTrackingUsersMutation>response.data.updateTrackingUsers;
  }
  async GetCardSettings(account: number): Promise<GetCardSettingsQuery> {
    const statement = `query GetCardSettings($account: Float!) {
        getCardSettings(account: $account) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {
      account
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetCardSettingsQuery>response.data.getCardSettings;
  }
  async GetOneClickUserLog(uid: string): Promise<GetOneClickUserLogQuery> {
    const statement = `query GetOneClickUserLog($uid: String!) {
        getOneClickUserLog(uid: $uid) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetOneClickUserLogQuery>response.data.getOneClickUserLog;
  }
  async GetPromotions(uid: string): Promise<GetPromotionsQuery> {
    const statement = `query GetPromotions($uid: String!) {
        getPromotions(uid: $uid) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetPromotionsQuery>response.data.getPromotions;
  }
  async GetSegments(uid: string): Promise<GetSegmentsQuery> {
    const statement = `query GetSegments($uid: String!) {
        getSegments(uid: $uid) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetSegmentsQuery>response.data.getSegments;
  }
  async GetTrackingUsers(uid: string): Promise<GetTrackingUsersQuery> {
    const statement = `query GetTrackingUsers($uid: String!) {
        getTrackingUsers(uid: $uid) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uid
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetTrackingUsersQuery>response.data.getTrackingUsers;
  }
  async ListCardSettings(
    filter?: TableCardSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCardSettingsQuery> {
    const statement = `query ListCardSettings($filter: TableCardSettingsFilterInput, $limit: Int, $nextToken: String) {
        listCardSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            account
            segmentRef
            showSpecialOffer
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListCardSettingsQuery>response.data.listCardSettings;
  }
  async ListOneClickUserLogs(
    filter?: TableOneClickUserLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOneClickUserLogsQuery> {
    const statement = `query ListOneClickUserLogs($filter: TableOneClickUserLogFilterInput, $limit: Int, $nextToken: String) {
        listOneClickUserLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            authorizationCode
            cardNumber
            cardType
            email
            responseCode
            uid
            username
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListOneClickUserLogsQuery>response.data.listOneClickUserLogs;
  }
  async ListPromotions(
    filter?: TablePromotionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPromotionsQuery> {
    const statement = `query ListPromotions($filter: TablePromotionsFilterInput, $limit: Int, $nextToken: String) {
        listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            amount
            basesDescription
            bonusAmount
            bonusToken
            color
            daysAvailable
            durationDescription
            enabled
            finalDate
            initialDate
            isDeleted
            isEnabledAllCustomers
            name
            paymentAmount
            segment_uid
            tickets
            token
            typeOfPromotion
            uid
            updateTime
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListPromotionsQuery>response.data.listPromotions;
  }
  async ListSegments(
    filter?: TableSegmentsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSegmentsQuery> {
    const statement = `query ListSegments($filter: TableSegmentsFilterInput, $limit: Int, $nextToken: String) {
        listSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            creationDate
            isDeleted
            lastUpdate
            name
            onlyNewCards
            promotionRef
            status
            uid
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListSegmentsQuery>response.data.listSegments;
  }
  async ListTrackingUsers(
    filter?: TableTrackingUsersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrackingUsersQuery> {
    const statement = `query ListTrackingUsers($filter: TableTrackingUsersFilterInput, $limit: Int, $nextToken: String) {
        listTrackingUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            cardNumber
            fidelity
            finalDate
            initialDate
            lastStepReached
            paymentAmount
            paymentMethod
            promotionCash
            promotionCashBonus
            promotionTickets
            promotionTokenBonus
            promotionTokens
            registeredEmail
            uid
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <ListTrackingUsersQuery>response.data.listTrackingUsers;
  }
  OnCreateCardSettingsListener(
    account?: number,
    showSpecialOffer?: boolean
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateCardSettings">>
  > {
    const statement = `subscription OnCreateCardSettings($account: Float, $showSpecialOffer: Boolean) {
        onCreateCardSettings(account: $account, showSpecialOffer: $showSpecialOffer) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (account) {
      gqlAPIServiceArguments.account = account;
    }
    if (showSpecialOffer) {
      gqlAPIServiceArguments.showSpecialOffer = showSpecialOffer;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateOneClickUserLogListener(
    authorizationCode?: number,
    email?: string,
    responseCode?: number,
    uid?: string,
    username?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateOneClickUserLog">>
  > {
    const statement = `subscription OnCreateOneClickUserLog($authorizationCode: Int, $email: String, $responseCode: Int, $uid: String, $username: String) {
        onCreateOneClickUserLog(
          authorizationCode: $authorizationCode
          email: $email
          responseCode: $responseCode
          uid: $uid
          username: $username
        ) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authorizationCode) {
      gqlAPIServiceArguments.authorizationCode = authorizationCode;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (responseCode) {
      gqlAPIServiceArguments.responseCode = responseCode;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreatePromotionsListener(
    amount?: number,
    basesDescription?: string,
    bonusAmount?: number,
    bonusToken?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreatePromotions">>
  > {
    const statement = `subscription OnCreatePromotions($amount: Int, $basesDescription: String, $bonusAmount: Int, $bonusToken: Int, $uid: String) {
        onCreatePromotions(
          amount: $amount
          basesDescription: $basesDescription
          bonusAmount: $bonusAmount
          bonusToken: $bonusToken
          uid: $uid
        ) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    if (basesDescription) {
      gqlAPIServiceArguments.basesDescription = basesDescription;
    }
    if (bonusAmount) {
      gqlAPIServiceArguments.bonusAmount = bonusAmount;
    }
    if (bonusToken) {
      gqlAPIServiceArguments.bonusToken = bonusToken;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateSegmentsListener(
    creationDate?: string,
    lastUpdate?: string,
    name?: string,
    status?: boolean,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateSegments">>
  > {
    const statement = `subscription OnCreateSegments($creationDate: AWSDateTime, $lastUpdate: AWSDateTime, $name: String, $status: Boolean, $uid: String) {
        onCreateSegments(
          creationDate: $creationDate
          lastUpdate: $lastUpdate
          name: $name
          status: $status
          uid: $uid
        ) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (creationDate) {
      gqlAPIServiceArguments.creationDate = creationDate;
    }
    if (lastUpdate) {
      gqlAPIServiceArguments.lastUpdate = lastUpdate;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnCreateTrackingUsersListener(
    cardNumber?: number,
    fidelity?: string,
    finalDate?: number,
    initialDate?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onCreateTrackingUsers">>
  > {
    const statement = `subscription OnCreateTrackingUsers($cardNumber: Float, $fidelity: String, $finalDate: Float, $initialDate: Float, $uid: String) {
        onCreateTrackingUsers(
          cardNumber: $cardNumber
          fidelity: $fidelity
          finalDate: $finalDate
          initialDate: $initialDate
          uid: $uid
        ) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cardNumber) {
      gqlAPIServiceArguments.cardNumber = cardNumber;
    }
    if (fidelity) {
      gqlAPIServiceArguments.fidelity = fidelity;
    }
    if (finalDate) {
      gqlAPIServiceArguments.finalDate = finalDate;
    }
    if (initialDate) {
      gqlAPIServiceArguments.initialDate = initialDate;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteCardSettingsListener(
    account?: number,
    showSpecialOffer?: boolean
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteCardSettings">>
  > {
    const statement = `subscription OnDeleteCardSettings($account: Float, $showSpecialOffer: Boolean) {
        onDeleteCardSettings(account: $account, showSpecialOffer: $showSpecialOffer) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (account) {
      gqlAPIServiceArguments.account = account;
    }
    if (showSpecialOffer) {
      gqlAPIServiceArguments.showSpecialOffer = showSpecialOffer;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteOneClickUserLogListener(
    authorizationCode?: number,
    email?: string,
    responseCode?: number,
    uid?: string,
    username?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteOneClickUserLog">>
  > {
    const statement = `subscription OnDeleteOneClickUserLog($authorizationCode: Int, $email: String, $responseCode: Int, $uid: String, $username: String) {
        onDeleteOneClickUserLog(
          authorizationCode: $authorizationCode
          email: $email
          responseCode: $responseCode
          uid: $uid
          username: $username
        ) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authorizationCode) {
      gqlAPIServiceArguments.authorizationCode = authorizationCode;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (responseCode) {
      gqlAPIServiceArguments.responseCode = responseCode;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeletePromotionsListener(
    amount?: number,
    basesDescription?: string,
    bonusAmount?: number,
    bonusToken?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeletePromotions">>
  > {
    const statement = `subscription OnDeletePromotions($amount: Int, $basesDescription: String, $bonusAmount: Int, $bonusToken: Int, $uid: String) {
        onDeletePromotions(
          amount: $amount
          basesDescription: $basesDescription
          bonusAmount: $bonusAmount
          bonusToken: $bonusToken
          uid: $uid
        ) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    if (basesDescription) {
      gqlAPIServiceArguments.basesDescription = basesDescription;
    }
    if (bonusAmount) {
      gqlAPIServiceArguments.bonusAmount = bonusAmount;
    }
    if (bonusToken) {
      gqlAPIServiceArguments.bonusToken = bonusToken;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteSegmentsListener(
    creationDate?: string,
    lastUpdate?: string,
    name?: string,
    status?: boolean,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteSegments">>
  > {
    const statement = `subscription OnDeleteSegments($creationDate: AWSDateTime, $lastUpdate: AWSDateTime, $name: String, $status: Boolean, $uid: String) {
        onDeleteSegments(
          creationDate: $creationDate
          lastUpdate: $lastUpdate
          name: $name
          status: $status
          uid: $uid
        ) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (creationDate) {
      gqlAPIServiceArguments.creationDate = creationDate;
    }
    if (lastUpdate) {
      gqlAPIServiceArguments.lastUpdate = lastUpdate;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnDeleteTrackingUsersListener(
    cardNumber?: number,
    fidelity?: string,
    finalDate?: number,
    initialDate?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onDeleteTrackingUsers">>
  > {
    const statement = `subscription OnDeleteTrackingUsers($cardNumber: Float, $fidelity: String, $finalDate: Float, $initialDate: Float, $uid: String) {
        onDeleteTrackingUsers(
          cardNumber: $cardNumber
          fidelity: $fidelity
          finalDate: $finalDate
          initialDate: $initialDate
          uid: $uid
        ) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cardNumber) {
      gqlAPIServiceArguments.cardNumber = cardNumber;
    }
    if (fidelity) {
      gqlAPIServiceArguments.fidelity = fidelity;
    }
    if (finalDate) {
      gqlAPIServiceArguments.finalDate = finalDate;
    }
    if (initialDate) {
      gqlAPIServiceArguments.initialDate = initialDate;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateCardSettingsListener(
    account?: number,
    showSpecialOffer?: boolean
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateCardSettings">>
  > {
    const statement = `subscription OnUpdateCardSettings($account: Float, $showSpecialOffer: Boolean) {
        onUpdateCardSettings(account: $account, showSpecialOffer: $showSpecialOffer) {
          __typename
          account
          segmentRef
          showSpecialOffer
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (account) {
      gqlAPIServiceArguments.account = account;
    }
    if (showSpecialOffer) {
      gqlAPIServiceArguments.showSpecialOffer = showSpecialOffer;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateOneClickUserLogListener(
    authorizationCode?: number,
    email?: string,
    responseCode?: number,
    uid?: string,
    username?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateOneClickUserLog">>
  > {
    const statement = `subscription OnUpdateOneClickUserLog($authorizationCode: Int, $email: String, $responseCode: Int, $uid: String, $username: String) {
        onUpdateOneClickUserLog(
          authorizationCode: $authorizationCode
          email: $email
          responseCode: $responseCode
          uid: $uid
          username: $username
        ) {
          __typename
          authorizationCode
          cardNumber
          cardType
          email
          responseCode
          uid
          username
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (authorizationCode) {
      gqlAPIServiceArguments.authorizationCode = authorizationCode;
    }
    if (email) {
      gqlAPIServiceArguments.email = email;
    }
    if (responseCode) {
      gqlAPIServiceArguments.responseCode = responseCode;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdatePromotionsListener(
    amount?: number,
    basesDescription?: string,
    bonusAmount?: number,
    bonusToken?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdatePromotions">>
  > {
    const statement = `subscription OnUpdatePromotions($amount: Int, $basesDescription: String, $bonusAmount: Int, $bonusToken: Int, $uid: String) {
        onUpdatePromotions(
          amount: $amount
          basesDescription: $basesDescription
          bonusAmount: $bonusAmount
          bonusToken: $bonusToken
          uid: $uid
        ) {
          __typename
          amount
          basesDescription
          bonusAmount
          bonusToken
          color
          daysAvailable
          durationDescription
          enabled
          finalDate
          initialDate
          isDeleted
          isEnabledAllCustomers
          name
          paymentAmount
          segment_uid
          tickets
          token
          typeOfPromotion
          uid
          updateTime
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (amount) {
      gqlAPIServiceArguments.amount = amount;
    }
    if (basesDescription) {
      gqlAPIServiceArguments.basesDescription = basesDescription;
    }
    if (bonusAmount) {
      gqlAPIServiceArguments.bonusAmount = bonusAmount;
    }
    if (bonusToken) {
      gqlAPIServiceArguments.bonusToken = bonusToken;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateSegmentsListener(
    creationDate?: string,
    lastUpdate?: string,
    name?: string,
    status?: boolean,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateSegments">>
  > {
    const statement = `subscription OnUpdateSegments($creationDate: AWSDateTime, $lastUpdate: AWSDateTime, $name: String, $status: Boolean, $uid: String) {
        onUpdateSegments(
          creationDate: $creationDate
          lastUpdate: $lastUpdate
          name: $name
          status: $status
          uid: $uid
        ) {
          __typename
          creationDate
          isDeleted
          lastUpdate
          name
          onlyNewCards
          promotionRef
          status
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (creationDate) {
      gqlAPIServiceArguments.creationDate = creationDate;
    }
    if (lastUpdate) {
      gqlAPIServiceArguments.lastUpdate = lastUpdate;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }

  OnUpdateTrackingUsersListener(
    cardNumber?: number,
    fidelity?: string,
    finalDate?: number,
    initialDate?: number,
    uid?: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onUpdateTrackingUsers">>
  > {
    const statement = `subscription OnUpdateTrackingUsers($cardNumber: Float, $fidelity: String, $finalDate: Float, $initialDate: Float, $uid: String) {
        onUpdateTrackingUsers(
          cardNumber: $cardNumber
          fidelity: $fidelity
          finalDate: $finalDate
          initialDate: $initialDate
          uid: $uid
        ) {
          __typename
          cardNumber
          fidelity
          finalDate
          initialDate
          lastStepReached
          paymentAmount
          paymentMethod
          promotionCash
          promotionCashBonus
          promotionTickets
          promotionTokenBonus
          promotionTokens
          registeredEmail
          uid
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (cardNumber) {
      gqlAPIServiceArguments.cardNumber = cardNumber;
    }
    if (fidelity) {
      gqlAPIServiceArguments.fidelity = fidelity;
    }
    if (finalDate) {
      gqlAPIServiceArguments.finalDate = finalDate;
    }
    if (initialDate) {
      gqlAPIServiceArguments.initialDate = initialDate;
    }
    if (uid) {
      gqlAPIServiceArguments.uid = uid;
    }
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }
}
