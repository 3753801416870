import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionsCarouselComponent } from './promotions-carousel.component';

@NgModule({
  declarations: [PromotionsCarouselComponent],
  imports: [CommonModule],
  exports: [PromotionsCarouselComponent],
})
export class PromotionsCarouselModule {}
