import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Promotions } from '../model/promotion.model';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private router: Router) {}

  redirectToCardErrorPage(): void {
    this.router.navigate(['/card-error']);
  }

  redirectToCriticalErrorPage(): void {
    this.router.navigate(['/server-error']);
  }

  lightenColor(color: string, percent: number = 20): string {
    let r: number, g: number, b: number;
    let format: 'rgb' | 'hex';

    if (color.startsWith('rgb')) {
      // Parsear formato rgb(r, g, b)
      const rgbValues = color.match(/\d+/g);
      if (!rgbValues || rgbValues.length < 3) {
        throw new Error('Formato RGB inválido');
      }
      r = parseInt(rgbValues[0], 10);
      g = parseInt(rgbValues[1], 10);
      b = parseInt(rgbValues[2], 10);
      format = 'rgb';
    } else if (color.startsWith('#')) {
      // Parsear formato hexadecimal #RRGGBB
      if (color.length === 7) {
        r = parseInt(color.substr(1, 2), 16);
        g = parseInt(color.substr(3, 2), 16);
        b = parseInt(color.substr(5, 2), 16);
      } else if (color.length === 4) {
        // Manejar formato hexadecimal corto #RGB
        r = parseInt(color.charAt(1) + color.charAt(1), 16);
        g = parseInt(color.charAt(2) + color.charAt(2), 16);
        b = parseInt(color.charAt(3) + color.charAt(3), 16);
      } else {
        throw new Error('Formato hexadecimal inválido');
      }
      format = 'hex';
    } else {
      throw new Error('Formato de color no reconocido');
    }

    // Convertir RGB a HSL
    const hsl = this.rgbToHsl(r, g, b);

    // Aumentar la luminosidad
    hsl.l += percent / 100;
    if (hsl.l > 1) hsl.l = 1;

    // Convertir de vuelta a RGB
    const rgb = this.hslToRgb(hsl.h, hsl.s, hsl.l);

    // Devolver el color en el mismo formato de entrada
    if (format === 'rgb') {
      return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    } else {
      return `#${this.componentToHex(rgb.r)}${this.componentToHex(rgb.g)}${this.componentToHex(rgb.b)}`;
    }
  }

  // Funciones auxiliares
  private rgbToHsl(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h = 0,
      s = 0,
      l = (max + min) / 2;

    if (max !== min) {
      const delta = max - min;
      s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
      switch (max) {
        case r:
          h = (g - b) / delta + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / delta + 2;
          break;
        case b:
          h = (r - g) / delta + 4;
          break;
      }
      h /= 6;
    }

    return { h, s, l };
  }

  private hslToRgb(h: number, s: number, l: number) {
    let r: number, g: number, b: number;

    if (s === 0) {
      r = g = b = l; // Color gris
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  }

  private componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  public isCardEnabled(item: Promotions, enrollInformation: User = null): boolean {
    return enrollInformation !== null || item.isEnabledAllCustomers;
  }

  public goToPayment(source: Promotions, enrollInformation: User = null): void {
    if (enrollInformation === null && source.isEnabledAllCustomers) {
      localStorage.setItem('promotion-data', JSON.stringify(source));
      this.router.navigate([
        './payment-page',
        {
          card: localStorage.getItem('card-code'),
        },
      ]);
    } else if (enrollInformation != null && !source.isEnabledAllCustomers) {
      localStorage.setItem('promotion-data', JSON.stringify(source));
      this.router.navigate([
        './payment-page',
        {
          card: localStorage.getItem('card-code'),
        },
      ]);
    }
  }
}
